<template>
    <div style="text-align: left;">
        <el-card>
            <el-page-header @back="goBack" content="" class="m-b-30">
            </el-page-header>
            <div v-for="(v,i) of list" :key="v.id" :id="v.id">
                <div class="index">第{{i+1}}张</div>
                <myform :form="v" @callBack="callBack"></myform>
            </div>
            <div class="index text-center"> <el-button type="primary" @click="add" v-if="!list || list.length<20">添加</el-button></div>
        </el-card>
        <!-- {{list[0]}} -->
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";
import { adEnterprise } from "@/service"
import { localSet, localGet, localDel } from "@/store/store"
import myform from "./components/MyForm.vue"
export default {
    name: "Photo",
    components: {
        myform,
    },
    data() {
        return {
            list: [],
            adImgFlag: null,
        }
    },
    mounted() {
        let enterprise = localGet('enterprise')
        if (enterprise && enterprise.id) {
            this.enterpriseId = enterprise.id
        }
        this.adImgFlag = this.$route.query.adImgFlag || 0
        this.queryAdEnterprises()
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 0)
    },
    methods: {
        // 查询
        queryAdEnterprises() {
            adEnterprise.queryAdEnterprises({ adImgFlag: this.adImgFlag*1, enterpriseId: this.enterpriseId }).then(res => {
                if (res) {
                    let index = 0
                    for (let v of res) {
                        ++index
                        v.index = index
                        if (v.adLinkType == 2) {
                            v.spuObj = {}
                            this.querySpu(v)
                        } else
                        if (v.adLinkType == 1) {
                            v.url = v.adLinkUrl
                        }
                    }
                    this.setIndex(res)
                    this.list = res
                    if(this.$route.query.id){
                        setTimeout(()=>{
                            this.fdkkjfd(this.$route.query.id)
                        },300)
                    }
                }else{
                    this.add()
                }
            })
        },
        fdkkjfd(id){
            var element = document.getElementById(id);
            // console.log(666,element)
            if(!element)return
            // element.scrollIntoView();    元素的顶端将和其所在滚动区的可视区域的顶端对齐
            // element.scrollIntoView(false); 元素的底端将和其所在滚动区的可视区域的底端对齐
            // element.scrollIntoView({block: "end"});
            element.scrollIntoView({behavior: "instant", block: "center", inline: "nearest"});
        },
        callBack(v, t) {
            console.log(v)
            console.log(t)
            if (t == 'up') {
                this.$oucy.listUpDnMove(this.list, 'up', v.index)    
                this.setIndex()
                this.updateAdEnterprise(this.list[v.index])       
                this.updateAdEnterprise(this.list[v.index+1])       
            } else
            if (t == 'dn') {
                this.$oucy.listUpDnMove(this.list, 'dn', v.index)
                this.setIndex()
                this.updateAdEnterprise(this.list[v.index])       
                this.updateAdEnterprise(this.list[v.index-1])   
            }else
            if (t == 'create') {
                this.createAdEnterprise(v)
            }else
            if(t=='del'){
                if(v.id){
                   this.delAdEnterprise(v) 
                }else{
                    this.list.splice(v.index,1)
                }
                this.setIndex()
                for(let vv of this.list){
                    this.updateAdEnterprise(vv)       
                }
            }else
            if(t=='edit'){
                this.updateAdEnterprise(v)       
            }
        },
        createAdEnterprise(v) {
            v.enterpriseId = this.enterpriseId
            v.adImgFlag = this.adImgFlag*1
            adEnterprise.createAdEnterprise(v).then(res => {
                v.id=res
                this.list[v.index]=Object.assign(this.list[v.index],v)
                this.$set(this.list,v.index, v)
                this.$message('添加成功')
            })
        },
        updateAdEnterprise(v) {
            if(!v.id)return
            v.enterpriseId = this.enterpriseId
            v.adImgFlag = this.adImgFlag*1
            adEnterprise.updateAdEnterprise(v).then(res => {
                this.$message('更新成功')
            })
        },
        delAdEnterprise(v) {
            adEnterprise.delAdEnterprise(v).then(res => {
                this.$message('删除成功')
                this.list.splice(v.index,1)
            })
        },
        querySpu(v, i) {
            oucy.postRequest('/client/product/spu/querySpu', {
                spuId: v.adLinkUrl,
            }).then(res => {
                v.spuObj = res || {}
            });
        },
        setIndex(list = this.list) {
            for (let i = 0; i < list.length; i++) {
                list[i].index = i
            }
        },
        add() {
            this.list.push(Object.assign({}, {
                // 是否启用
                adEnable: true,
                // 广告图片
                adImg: '',
                // 属性 0 pc端 1 移动端
                adImgFlag: 0,

                // 链接类型 0无链接 1网页地址 2商品
                adLinkType: 0,
                // 企业编码
                enterpriseId: null,
                // 链接地址
                url: null,
                // 关联商品
                spuId: null,
                adLinkUrl: null,
                spuObj: {},

            }))
            this.setIndex()
        },
        goBack(){
            this.$oucy.back()
        },
    }
}
</script>
<style scoped lang="less">
.avatar-uploader-icon {
    border: 1px dashed #c0ccda;
    width: 54px;
    height: 54px;
    line-height: 54px;
}

.avatar.mini {
    width: 54px;
    height: 54px;
}

.info {
    padding-left: 20px;
    line-height: 1.5;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #AAAAAA;
}

.index {
    background: #F2F4F6;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #666666;
    padding: 18px;
    margin-bottom: 30px;
}

.spu {
    background: #F6F7F8;
    border-radius: 1px;
    display: flex;
    align-items: center;
    padding: 12px;
    justify-content: space-between;
}

.spu .left {
    display: flex;
    align-items: center;

    span {
        font-size: 12px;
        font-family: SimSun;
        font-weight: 400;
        color: #666666;
        padding-left: 24px;
    }

    img {
        width: 60px;
        height: 60px;
    }
}

.spu .right {
    font-size: 12px;
    font-family: SimSun;
    font-weight: 400;
    color: #333333;
}
</style>