<template>
    <div style="text-align: left;">
        <el-form ref="form" :model="form" :rules="formRules" label-width="80px">
            <div>
                <el-form-item label="图片" prop="adImg">
                    <upload-image ref="upload" @uploadImageBack="uploadImageBack" class="avatar-uploader" size="mini" :ratio="adImgFlag==0?[3.84,1]:[1.875,1]" v-if="hasMounted">
                        <img v-if="form.adImg" :src="$oucy.ossUrl+form.adImg"  ref="upload" class="avatar mini">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </upload-image>
<!--                     <el-upload accept="image/jpeg,image/gif,image/png" ref="upload" :auto-upload="true" class="avatar-uploader" action="fakeaction" :show-file-list="false" :http-request="handleAvataImgUpload"  :limit="1">
                                          <img v-if="form.adImg" :src="$oucy.ossUrl+form.adImg" class="avatar mini">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload> -->

                    <div class="info">
                        <div>图片规格</div>
                        <div v-if="adImgFlag==0">1.宽高1920*500</div>
                        <div v-else>1.宽高750*400</div>
                        <div>2.大小3M以内</div>
                    </div>
                </el-form-item>
                <el-form-item label="关联">
                    <el-radio-group v-model="form.adLinkType">
                        <el-radio :label="0">无</el-radio>
                        <el-radio :label="2">商品</el-radio>
                        <el-radio :label="1">链接</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="链接" v-if="form.adLinkType==1" prop="url">
                    <el-input v-model="form.url" placeholder="请输入点击跳转链接（以http://或https://开头）"></el-input>
                </el-form-item>
                <el-form-item label="商品" v-if="form.adLinkType==2" prop="spuId">
                    <div class="spu" v-if="form.spuObj && form.spuObj.id" @click="openDialogFormVisible()">
                        <div class="left">
                            <img :src="$oucy.ossUrl + form.spuObj.cover" alt="">
                            <span>{{form.spuObj.spuName}}</span>
                        </div>
                        <div class="right">
                            <span class="spuPrise" v-if="form.spuObj.spuPrise=='0.00'">面议</span>
                            <span class="spuPrise" v-else>￥{{form.spuObj.spuPrise}}</span>
                        </div>
                    </div>
                    <div v-else>
                        <el-button type="primary" plain icon="el-icon-circle-plus-outline" size="mini" @click="openDialogFormVisible()">点击添加</el-button>
                    </div>
                </el-form-item>
                <el-form-item label="文字">
                    <el-input v-model="form.adText" placeholder="请输入文字描述"></el-input>
                </el-form-item>
                <el-form-item label="状态">
                    <el-radio-group v-model="form.adEnable">
                        <el-radio :label="true">启用</el-radio>
                        <el-radio :label="false">关闭</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">保存</el-button>
                    <el-button @click="callBack('del')"> 删除</el-button>
                    <el-button @click="callBack('dn')" icon="el-icon-bottom"></el-button>
                    <el-button @click="callBack('up')" icon="el-icon-top"></el-button>
                </el-form-item>
            </div>
        </el-form>
        <el-dialog width="600px" title="添加商品" :visible.sync="dialogFormVisible">
            <template>
                <div>
                    <el-input v-model="spuName" placeholder="请输入关键字搜索" size="mini" class="m-l-10" style="width: 150px;"></el-input>
                    <el-button type="default" size="mini" @click="search">搜索</el-button>
                </div>
                <el-table :data="prlductData.content" style="width: 100%">
                    <!-- <el-table-column type="selection" width="55">                         </el-table-column> -->
                    <!-- <el-table-column type="index" label="编号"></el-table-column> -->
                    <el-table-column prop="date" label="产品封面" width="100">
                        <template slot-scope="scope">
                            <!-- <video v-if="scope.row.spuVideo" :src="$oucy.ossUrl+scope.row.spuVideo" style="width:40px;height: 40px;background: #f1f1f1;" controls="1"></video> -->
                            <!-- <div v-else> -->
                            <el-avatar shape="square" :src="scope.row.cover" v-if="hasImportImg(scope.row.cover)"></el-avatar>
                            <el-avatar shape="square" :src="$oucy.ossUrl+scope.row.cover" v-else></el-avatar>
                            <!-- </div> -->
                        </template>
                    </el-table-column>
                    <el-table-column prop="spuName" label="产品名称/所属品牌">
                        <template slot-scope="scope">
                            <div class="pointer line-1" :title="scope.row.spuName" @click="openNewPage(scope.row)">{{scope.row.spuName}}</div>
                            <div class="c-2" v-if="scope.row.brand && scope.row.brand.brandName">{{scope.row.brand.brandName}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="spuPrise" label="当前价格">
                        <template slot-scope="scope">
                            <span class="spuPrise" v-if="scope.row.spuPrise=='0.00'">面议</span>
                            <span class="spuPrise" v-else>￥{{scope.row.spuPrise}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="80">
                        <template slot-scope="scope">
                            <el-button type="default" size="mini" @click="addSpu(scope.row)">选择</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </template>
            <el-pagination v-if="prlductData" background layout="sizes, prev, pager, next, jumper, ->, total, slot" :page-size="prlductData.size" :total="prlductData.totalElements" @size-change="sizeChange" @current-change="currentChange" @prev-click="currentChange" @next-click="currentChange">
            </el-pagination>
        </el-dialog>
    </div>
</template>
<script>
import oss from "@/util/oss.js"
import oucy from "@/util/oucyUtil";
import { adEnterprise, spu } from "@/service"
import { localSet, localGet, localDel } from "@/store/store"

export default {
    name: "myform",
    data() {
        return {
            // 下面是验证的
            formRules: {

                adImg: [
                    { required: true, message: '请选择图片', trigger: 'blur' },
                ],
                url: [
                    { required: true, message: '请输入点击跳转链接', trigger: 'blur' },
                ],
                spuObj: [
                    { required: true, message: '请设置关联商品', trigger: 'blur' },
                ],
            },

            myform: {
                // 是否启用
                adEnable: true,
                // 广告图片
                adImg: '',
                // 属性 0 pc端 1 移动端
                adImgFlag: 0,

                // 链接类型 0无链接 1网页地址 2商品
                adLinkType: 0,
                // 企业编码
                enterpriseId: null,
                // 链接地址
                url: null,
                // 关联商品
                adLinkUrl: null,
                spuObj:{},

            },
            addObj: {},
            dialogFormVisible: false,
            limit: 5,
            start: 0,
            prlductData: {},
            spuName: null,
            adImgFlag: 0,
            hasMounted: false,
        }
    },
    props: {
        form: {
            type: Object,
            default: {
                // 是否启用
                adEnable: true,
                // 广告图片
                adImg: '',
                // 属性 0 pc端 1 移动端
                adImgFlag: 0,

                // 链接类型 0无链接 1网页地址 2商品
                adLinkType: 0,
                // 企业编码
                enterpriseId: null,
                // 链接地址
                url: null,
                // 关联商品
                // spuId: null,
                adLinkUrl: null,
                spuObj:{},
                index:null

            }
        }
    },

    mounted() {
        let enterprise = localGet('enterprise')
        if (enterprise && enterprise.id) {
            this.enterpriseId = enterprise.id
        }
        this.adImgFlag = this.$route.query.adImgFlag||0
        console.log('mounted',this.adImgFlag)
        setTimeout(()=>{
            this.hasMounted=true
        })

    },
    methods: {
        // 产品列表
        // 
        search() {
            // this.limit=10
            this.start = 0
            this.findAllFurnitureSpu()
        },
        sizeChange: function(limit) {
            const me = this;
            me.limit = limit;
            me.findAllFurnitureSpu();
        },
        currentChange: function(current) {
            const me = this;
            me.start = current - 1;
            me.findAllFurnitureSpu();
        },
        findAllFurnitureSpu() {
            spu.findAllFurnitureSpu({
                spuName: this.spuName,
                limit: this.limit,
                start: this.start,
                isShelves: true, //  是否上下架 false 下架 ，true 上架
                spuAuditStatus: 2, //审核状态 1待审核 2审核通过 3审核驳回
                enterpriseId: this.enterpriseId
            }).then(res => {
                console.log(res)
                this.prlductData = res
            });
        },
        hasImportImg(v) {
            return v && v.startsWith('//')
        },

        openDialogFormVisible() {
            // this.addObj=v
            this.findAllFurnitureSpu()
            this.dialogFormVisible = true
        },

        addSpu(v) {
            // this.addObj = v
            this.form.spuObj = v
            this.form.spuId = v.id
            this.dialogFormVisible = !true
        },


        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error('上传头像图片只能是 JPG 格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isJPG && isLt2M;
        },
        // 上传回调
        uploadImageBack(v) {
            this.form.adImg = v
            this.$refs.upload && this.$refs.upload.clearFiles && this.$refs.upload.clearFiles()
        },
        // 点击发布触发
        onSubmit() {
            this.$refs["form"].validate((valid) => {
                if (this.form.adLinkType == 2) {
                    this.form.adLinkUrl = this.form.spuObj.id
                } else
                if (this.form.adLinkType == 1) {
                    this.form.adLinkUrl = this.form.url
                }
                if (this.form.id) {
                    // this.updateEnterpriseNewsAlbumItem()
                    this.callBack('edit')
                } else {
                    this.callBack('create')
                    // this.createAdEnterprise()
                }
            })
        },
        createAdEnterprise() {
            this.form.enterpriseId = this.enterpriseId
            this.form.adImgFlag = this.adImgFlag
            adEnterprise.createAdEnterprise(this.form).then(res => {
                console.log()
            })
        },
        callBack(type){
            this.$emit('callBack',this.form,type)
        },
        // 上传
        handleAvataImgUpload: function(params) {
            const me = this;
            const file = params.file;
            let Oss=new oss()
            Oss.startUpload(file).then(res => {
            // me.$oucy.uploadRequest(file).then(res => {
                me.uploadImageBack(res)
                // me.enterApplyForm.avata = res;
            });
        },

    }
}
</script>
<style scoped lang="less">
.avatar-uploader-icon {
    border: 1px dashed #c0ccda;
    width: 54px;
    height: 54px;
    line-height: 54px;
}

.avatar.mini {
    width: 54px;
    height: 54px;
}

.info {
    padding-left: 20px;
    line-height: 1.5;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #AAAAAA;
}

.index {
    background: #F2F4F6;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #666666;
    padding: 18px;
    margin-bottom: 30px;
}

.spu {
    background: #F6F7F8;
    border-radius: 1px;
    display: flex;
    align-items: center;
    padding: 12px;
    justify-content: space-between;
}

.spu .left {
    display: flex;
    align-items: center;

    span {
        font-size: 12px;
        font-family: SimSun;
        font-weight: 400;
        color: #666666;
        padding-left: 24px;
    }

    img {
        width: 60px;
        height: 60px;
    }
}

.spu .right {
    font-size: 12px;
    font-family: SimSun;
    font-weight: 400;
    color: #333333;
}
</style>